<template>
    <div class="home">
        <!-- 第一部分 -->
        <header>
            <div style="width: 100%;display: flex;">
                <img class="sort" src="../../assets/img/分类.png" alt="">
                <img class="logo" src="../../assets/img/zuimeikebenlogo.png" alt="">
            </div>
        </header>
        <!-- 左侧导航栏 -->
        <div class="header_left">
            <div class="bg-model">
                <div class='content'>
                    <img class="back" src="../../assets/img/back.png" alt="">
                    <div style="width: 100%;text-align: center">
                        <img class="logo_big" src="../../assets/img/zuimeikebenlogo.png" alt="">
                    </div>
                    <ul>
                        <a class="fs" @click="linkToPage(1)">
                            <li class="clearfix" style="padding-top: .3rem;">
                                <img style="width: .44rem;height: .44rem;padding-left: .26rem"
                                    src="../../assets/img/课程.png" alt="">
                                <span class="fs left_text" style="padding-left: .37rem">
                                    最美课本
                                </span>
                                <img class="more" src="../../assets/img/more.png" alt="">
                            </li>
                        </a>
                        <a class="fs" @click="linkToPage(2)">
                            <li class="clearfix">
                                <img style="width: .44rem;height: .44rem;padding-left: .26rem"
                                    src="../../assets/img/公益.png" alt="">
                                <span class="fs left_text" style="padding-left: .37rem">
                                    教育公益
                                </span>
                                <img class="more" src="../../assets/img/more.png" alt="">
                            </li>
                        </a>

                        <a class="fs" @click="linkToPage(3)">
                            <li class="clearfix">
                                <img style="width: .44rem;height: .44rem;padding-left: .26rem"
                                    src="../../assets/img/合作.png" alt="">
                                <span class="fs left_text" style="padding-left: .31rem">
                                    合作共赢

                                </span>
                                <img class="more" src="../../assets/img/more.png" alt="">
                            </li>
                        </a>

                        <a class="fs" @click="linkToPage(4)">
                            <li class="clearfix">
                                <img style="width: .44rem;height: .44rem;padding-left: .26rem"
                                    src="../../assets/img/新闻.png" alt="">
                                <span class="fs left_text" style="padding-left: .33rem">
                                    公司新闻
                                </span>
                                <img class="more" src="../../assets/img/more.png" alt="">
                            </li>
                        </a>

                        <a class="fs" @click="linkToPage(5)">
                            <li class="clearfix">
                                <img style="width:.44rem;height:.44rem;padding-left: .26rem"
                                    src="../../assets/img/关于我们.png" alt="">
                                <span style="padding-left: .34rem">
                                    关于我们
                                </span>
                                <img class="more" src="../../assets/img/more.png" alt="">
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 第二部分 -->
        <div class="mid">
            <div class="present">
                <div class="graphic">
                    <span class="course fb">
                        让学习
                    </span>
                    <span class="interesting fb">
                        有趣味 变美好
                    </span>
                </div>
                <div class="study fs">
                    学生应景学、趣味学、自主学
                </div>
                <div style="margin-top: .2rem;padding-left: 5%;text-align: center">
                    <div class="photo">
                        <img class="boy" src="../../assets/img/img_boy.png" alt="">
                        <img class="girl" src="../../assets/img/img_girl.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="curriculum">
            <div class="curriculum_header">
                <p class="primary fb">最美课本</p>
                <span class="passive fb">
                    让学生从被动学习变为
                </span><span class="initiative fb">
                    主动学习
                </span>
            </div>
            <div class="lore">
                <div class="video_one">
                    <div class="video_bg">
                        <p class="video_img" @click="playVideo" v-show="!isShow">
                            <span class="video_play"></span>
                        </p>
                        <div class="video_open" v-show="isShow">
                            <video
                                ref="videoPlayer"
                                preload
                                disablepictureinpicture
                                controlslist="nodownload"
                                controls
                                width="550" height="300"
                                src="http://file.zjx.com/mp4/1568712089502.mp4">
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第四部分 -->
        <div class="intro">
            <div class="intro_list">
                <ul>
                    <li style="padding-left: .2rem">
                        <img src="../../assets/img/课程优势.png" alt="">
                        <p class="fb">课程优势</p>
                        <span class="fs">同步、双语配音、应景学习、美育 </span>
                    </li>
                    <li>
                        <img src="../../assets/img/学科分类.png" alt="">
                        <p class="fb">学科分类</p>
                        <span class="fs">语文 数学 英语</span>
                    </li>
                    <li>
                        <img src="../../assets/img/年级课程.png" alt="">
                        <p class="fb">年级课程</p>
                        <span class="fs">1-6年级</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 第五部分 -->
        <div class="know">
            <div class="know_content">
                <div class="publicize">
                    <span class="experience fb">
                        让孩子体会到：原来学习是一件很
                    </span>
                    <span class="fine fb" style="font-size: .4rem">
                        美好
                    </span>
                    <span class="thing fb">
                        的事情
                    </span>
                    <p class="textbook fs">让学生从被动学习变为主动学习</p>
                </div>
                <ul class="oul">
                    <li style="padding-bottom: .2rem">
                        <div class="title_top">
                            <img class="orange" src="../../assets/img/img_orange.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p class="nice fb">体验美</p>
                            <p class="wire"></p>
                            <span class="explain fs">
                                学生通过听觉、 视觉、 思考
                                “全感官”学习
                            </span>
                        </div>
                    </li>
                    <li style="padding-bottom: .2rem">
                        <div class="title_content">
                            <img class="orange" src="../../assets/img/img_yellow.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p class="nice fb">创造美</p>
                            <p class="wire"></p>
                            <span class="explain fs">
                                学生可根据课程绘画、创作、
                                朗读、配音
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="title_right">
                            <img class="orange" src="../../assets/img/img_blue.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p style="padding: .4rem 0 .18rem 0;" class="nice fb">分享美</p>
                            <p class="wire"></p>
                            <span class="result fs">
                                学生将学习成果 可以分享
                                给家长、 同学、老师，或者 朋友圈
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 第六部分 -->
        <div class="culture">
            <div class="culture_content">
                <span class="thought fb">
                    全面培养学生的
                </span>
                <span class="creativity fb">
                    想象力
                </span>
                <span class="thought fb">
                    个性化
                </span>
                <br>
                <span class="creativity fb">
                    思维能力
                </span>
                <span class="thought fb">
                    和
                </span>
                <span class="creativity fb">
                    创造力
                </span>
            </div>
            <ul class="clearfix">
                <li class="fl">
                    <div class="learn_top_one fb">
                        学的快
                    </div>
                    <div class="learn_bottom_one">
                        <p class="fs" style="padding-top: .26rem">课前预习</p>
                        <p class="fs">课中学习</p>
                        <p class="fs">课后复习</p>
                    </div>
                </li>
                <li class="fl" style="padding-top: .01rem;">
                    <div class="learn_top_two fb">
                        学的快
                    </div>

                    <div class="learn_bottom_two">
                        <p class="fs" style="padding-top: .26rem">三减负：</p>
                        <p class="fs">为学生、家长、老师减负</p>
                    </div>
                </li>
                <li class="fl" style="padding-top: .36rem">
                    <div class="learn_top_three fb">
                        学的快
                    </div>
                    <div class="learn_bottom_three">
                        <p class="fs" style="padding-top: .26rem">分享学习成果给老师、</p>
                        <p class="fs">同学、家长、朋友圈</p>
                    </div>
                </li>
                <li class="fl" style="padding-top: .37rem">
                    <div class="learn_top_four fb">
                        学的快
                    </div>
                    <div class="learn_bottom_four">
                        <p class="fs" style="padding-top: .26rem">学生挣学习积分，</p>
                        <p class="fs">可以获得奖励</p>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 第七部分 -->
        <div class="down_page">
            <div class="down_info">
                <div class="app_info clearfix">
                    <p class="app_title fb">再佳学最美课本APP</p>
                    <p class="client fs">专属手机客户端</p>
                    <div class="fl" style="width: 60%">
                        <div class="ipone_down">
                            <div class="ios_info">
                                <img class="ios" src="../../assets/img/icon_ios.png" alt="">
                                <span class="ios_title fs">iOS苹果端下载</span>
                            </div>
                        </div>
                        <div class="android_down">
                            <div class="and_info">
                                <img class="android" src="../../assets/img/icon_andriod.png" alt="">
                                <span style="padding-top: .02rem" class="android_title fs">Android安卓端下载</span>
                            </div>
                        </div>
                    </div>
                    <img class="img_code fl" src="../../assets/img/img_QR code.png" alt="">
                </div>
            </div>
        </div>
        <!-- 尾部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>
    import $ from 'jquery'
    export default {
        data() {
            return {
                isShow: false
            };
        },
        mounted() {
            if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/');
            } else {
                window.addEventListener('resize', this.remScale());
            }
            this.navList();
        },
        methods: {
            linkToPage(val){
                if(val == 1){
                    this.$router.push('/classroomEn')
                }else if(val == 2){
                    this.$router.push('/educationEn')
                }else if(val == 3){
                    this.$router.push('/winEn')
                }else if(val == 4){
                    this.$router.push('/companyNewsEn')
                }else if(val == 5){
                    this.$router.push('/aboutEn')
                }
            },
            remScale() {
                var winW = document.documentElement.clientWidth;
                winW = winW > 750 ? 750 : winW;
                document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
            },
            playVideo(){
                this.isShow = true;
                this.$refs.videoPlayer.play();
            },
            navList(){
                if (/Android/i.test(navigator.userAgent)) {
                    $('.left_text').css('padding-top','.08rem');
                    $('.ios_title').css('padding-top','.02rem');
                    $('.android_title').css('padding-top','.05rem');
                }
                $('.sort').click(function () {
                    $(".bg-model").show();
                    document.documentElement.style.position = 'fixed';
                    document.body.style.overflow = 'hidden';
                })
                $('.back').click(function () {
                    $('.bg-model').hide();
                    document.documentElement.style.position = 'static';
                    document.body.style.overflow = '';
                })
                $('.bg-model').click(function () {
                    $('.bg-model').hide();
                    document.documentElement.style.position = 'static';
                    document.body.style.overflow = '';
                })
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../assets/css/home.scss';
</style>
